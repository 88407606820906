import React from 'react';

import { Text, SectionTitle } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import css from './style.module.scss';

export const Abouts = ({ children }) => <div className={css.aboutSection}>{children}</div>;

export const AboutUser = ({
  children, image, name, link,
}) => (
  <div className={css.aboutUser}>
    <div className={css.image}>
      <img src={image} alt={`image of ${name}`} />
    </div>
    <SectionTitle>{name}</SectionTitle>
    <Text className={css.bio}>{children}</Text>
    {link && (
      <Button link external to={link} className={css.link}>
        {link.replace('https://', '')}
      </Button>
    )}
  </div>
);

export default AboutUser;
