import React from 'react';
import InviteButton from 'components/ui/InviteButton';
import Button from 'components/ui/Button';
import Section from 'components/ui/Section';
import HeroBar from 'components/ui/HeroBar';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import AboutUser, { Abouts } from 'components/ui/AboutUser';
import { Text } from 'components/ui/Typography';

const AboutPage = () => (
  <Layout>
    <SEO title="About Metrical" />
    <Section
      title={() => (
        <span>
          About
          {' '}
          <b>Metrical</b>
        </span>
      )}
    >
      <br />
      <br />
      <br />
      There isn't a big company behind Metrical:
      {' '}
      <b>it's only 2 of us.</b>
      {' '}
      <br />
      <br />
      We created Metrical just because we wanted a better (and more private) way
      to track visits without exposing our visitors' and users' privacy or
      selling their data.
      <br />
      <br />
      We care about our users (and their visitors), so we will try in any way to
      keep Metrical the best web analytics tool we ever used.
      <br />
      <br />
      We use it everyday since April 2019 and we are pretty proud of it.
    </Section>
    <Section title="Who we are">
      <Abouts>
        <AboutUser
          name="Francesco"
          image="https://avatars.io/twitter/urcoilbisurco"
          link="https://francesco.space"
        >
          Francesco is the main developer and designer behind Metrical.
          <br />
          He is known for his great mustache and his love for tea 🍵
        </AboutUser>
        <AboutUser
          name="Virginia"
          image="https://instagram.fmxp6-1.fna.fbcdn.net/v/t51.2885-19/s320x320/69468375_2429210434066716_2009961050039910400_n.jpg?_nc_ht=instagram.fmxp6-1.fna.fbcdn.net&_nc_ohc=3Stg7-oW2RYAX-pAF79&oh=239953da73a2489b736f7e91fa73d14a&oe=5EDEC5FE"
          link="https://instagram.com/virginiapug"
        >
          Virginia is the social spirit of Metrical. If you see a great copy on
          the website or on our social profiles, 150% chances it was made by
          her.
          <br />
          She is always ready to go out and skate 🛹
        </AboutUser>
      </Abouts>
    </Section>
    <HeroBar>
      Try a privacy-focused alternative to Google Analytics.
      {'  '}
      <br />
      <br />
      <InviteButton link external to="https://app.metrical.xyz/register" track="visit_register">
        Register now
      </InviteButton>
    </HeroBar>
    <Section title="Contacts">
      <Text>We would love to hear from you. </Text>
      <br />
      <br />
      <div>
        <Button
          // className={css.link}
          link
          small
          external
          to="mailto:hello@metrical.xyz"
        >
          ✉️ Write an Email
        </Button>
        <br />
        <br />
        <Button

          link
          small
          external
          to="https://telegram.me/sono_franco"
        >
          💬Chat on Telegram
        </Button>
        <br />
        <br />
        <Button
          link
          small
          external
          to="https://twitter.com/urcoilbisurco"
        >
          🐦Find us on Twitter
        </Button>
      </div>
    </Section>
  </Layout>
);

export default AboutPage;
